import axios from "../config/http.js";
import baseUrl from "../config/env.js";

//  空烟弹生产订单
function selectAllPatchOrder(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectAllPatchOrder", params);
}
function patchOrderSave(params, ctx) {
  return axios.fetchPost(baseUrl + "/patchOrder/save", params);
}
function selectByPatchOrderId(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectByPatchOrderId", params);
}

function selectPatchVoltage(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectPatchVoltage", params);
}

function saveOrUpdatePatchVoltage(params, ctx) {
  return axios.fetchPost(baseUrl + "/patchOrder/saveOrUpdatePatchVoltage", params);
}

function selectPatchVoltageById(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectPatchVoltageById", params);
}

function selectPatchVoltageByDeviceId(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectPatchVoltageByDeviceId", params);
}

export default {
  selectAllPatchOrder,
  patchOrderSave,
  selectByPatchOrderId,
  selectPatchVoltage,
  saveOrUpdatePatchVoltage,
  selectPatchVoltageById,
  selectPatchVoltageByDeviceId,
};
