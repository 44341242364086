import axios from "../config/http.js";
import baseUrl from "../config/env.js";

//包装箱管理订单
function selectPackingBoxOrderList(params, ctx) {
  return axios.fetchGet(baseUrl + "/packingBoxOrder/selectPackingBoxOrderList", params);
}
function packingBoxOrderSave(params, ctx) {
  return axios.fetchPost(baseUrl + "/packingBoxOrder/save", params);
}
function selectByPackingBoxOrderId(params, ctx) {
  return axios.fetchGet(baseUrl + "/packingBoxOrder/selectByPackingBoxOrderId", params);
}

export default {
  selectPackingBoxOrderList,
  packingBoxOrderSave,
  selectByPackingBoxOrderId,
};
