import axios from "../config/http.js";
import baseUrl from "../config/env.js";

//产品检测订单列表  
function selectProductCheckOrderList(params, ctx) {
  return axios.fetchGet(baseUrl + "/productCheckOrder/selectProductCheckOrderList", params);
}
//保存产品检测订单
function saveProductCheckOrder(params, ctx) {
  return axios.fetchPost(baseUrl + "/productCheckOrder/saveProductCheckOrder", params);
}
//查询产品检测订单详情
function selectProductCheckOrderById(params, ctx) {
  return axios.fetchGet(baseUrl + "/productCheckOrder/selectProductCheckOrderById", params);
}
//查询订单详情
function findById(params, ctx) {
  return axios.fetchGet(baseUrl + "/productCheckOrder/findById", params);
}
//查询批次订单(过滤了AP02设备)
function selectAllBatchNumber(params, ctx) {
  return axios.fetchGet(baseUrl + "/productCheckOrder/selectAllBatchNumber", params);
}


export default {
  selectProductCheckOrderList,
  saveProductCheckOrder,
  selectProductCheckOrderById,
  findById,
  selectAllBatchNumber
};
